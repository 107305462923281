p {
  margin: 0.5rem 0; }

h1 {
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  font-size: 2.5rem; }

h2 {
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  font-size: 2rem; }

h3 {
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  font-size: 1.75rem; }

h4 {
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  font-size: 1.5rem; }

h5 {
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  font-size: 1.25rem; }

h6 {
  line-height: 1.2;
  margin-bottom: 0.5rem;
  font-family: "Nunito", sans-serif;
  font-weight: 800;
  font-size: 1rem; }

.main__nav-bar {
  height: 100%;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 200px;
  background-color: var(--navbar-bg);
  overflow: auto;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  z-index: 2; }

.main__page {
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background-color: var(--page-bg);
  overflow: auto;
  position: relative;
  padding: 2rem; }

.main__page section {
    min-height: 100vh;
    padding: 1rem; }

/*
The .window class creates a centered small window in the middle of 
the screen, if placed within .main or other container which centers it.
*/

.window {
  margin: 1rem;
  width: 100%;
  max-width: 480px;
  max-height: 100%;
  background: var(--bg-light);
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3); }

@media (max-width: 480px) {
    .window {
      margin: 0;
      height: 100%; } }

.window__body {
  padding: 1rem; }

.table {
  --table-primary-bg: #fafafa;
  --table-primary-color: black;
  --table-secondary-bg: #eeeeee;
  --table-secondary-color: black;
  --table-border-color: black;
  width: 100%;
  border: 1px solid var(--table-border-color); }

.table tr {
    background-color: var(--table-primary-bg);
    color: var(--table-primary-color); }

.table th {
    text-align: left;
    padding: 0.5rem; }

.table td {
    padding: 0.25rem 0.5rem; }

.table--striped tbody tr:nth-child(odd) {
  background-color: var(--table-secondary-bg);
  color: var(--table-secondary-color); }

.table--border-ruled tr {
  border-bottom: 1px solid var(--table-border-color); }

.table--border-ruled tfoot {
  border-bottom-width: 0; }

.table--border-grid tr {
  border-bottom: 1px solid var(--table-border-color); }

.table--border-grid tfoot {
  border-bottom-width: 0; }

.table--border-grid td {
  border-right: 1px solid var(--table-border-color); }

.table--border-grid td:last-child {
    border-right-width: 0; }

.table--fixed {
  table-layout: fixed; }

.nav-bar-menu {
  width: 100%; }

.nav-bar-menu ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none; }

.nav-bar-menu a {
    display: block;
    color: var(--menu-link-color); }

.nav-bar-menu a:hover {
      color: var(--menu-active-color); }

.nav-bar-menu li {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem; }

.nav-bar-menu li.active {
      background-color: var(--menu-bg); }

.nav-bar-menu li.active a {
        color: var(--menu-active-color);
        font-weight: 700; }

.nav-bar-menu > ul > li > a {
    font-weight: 400; }

.nav-bar-menu__heading {
  font-size: 1.125rem;
  color: var(--menu-text-color);
  margin-top: 0.5rem;
  margin-bottom: 0; }

.nav-bar-menu__heading a {
    color: var(--menu-text-color); }

.nav-bar-menu__spacing {
  padding: 0.5rem 0 !important; }

.nav-bar-menu__logout:hover {
  background-color: var(--danger); }

.nav-bar-menu__logout:hover a {
    color: white; }

.editorial code {
  display: block;
  padding: 10px;
  background-color: #fafafa;
  border: 1px solid #ccc;
  margin: 10px 0;
  overflow-x: auto; }

.documentation-method {
  font-weight: bold; }

.documentation-errors {
  padding-left: 0;
  margin: 0; }

.documentation-error {
  list-style: none; }

.documentation-error-code {
  font-weight: bold; }

.documentation-error-code::after {
  content: ':'; }

.documentation-parameters {
  padding-left: 0;
  margin: 0; }

.documentation-parameter {
  list-style: none; }

.documentation-parameter-name {
  font-weight: bold; }

.documentation-parameter-type {
  font-style: italic; }

.documentation-parameter-description {
  color: var(--faded); }

.documentation-parameter-required {
  color: var(--warning); }
